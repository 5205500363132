
    ::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: #acabab;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: rgb(119, 119, 119);
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: rgb(95, 95, 95);
      }
